<!-- 酒店管理-订单管理-套票订单 -->

<template>
  <div class="order-assoc main-cnt">
    <div class="title">
      订单列表
    </div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :needExport="true"
        :needPrint="true" :filters="filters" :groupFilters="groupFilters" :ispaging="true"
        :apiName="HotelApi.getAssocOrder" :columns="tableColumns" @onDetailBtn="onDetailBtn" @hotelChange="hotelChange"
        @scenicSpotChange="scenicSpotChange" @storeChange="storeChange" @dinningChange="dinningChange"
        @export="handleExport" @print="handlePrint">
        <!-- 支付状态 -->
        <template #pay_status_text="{ row }">
          <div class="status-text" :class="orderStatusColors(row.pay_status, 'pay')">{{ row.pay_status_text }}</div>
        </template>

        <!-- 业务状态 -->
        <template #work_status_text="{ row }">
          <div class="status-text" :class="orderStatusColors(row.work_status, 'work')">{{ row.work_status_text }}</div>
        </template>

        <!-- 发起退款 -->
        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == 1 && authData.indexOf('h_ZLY1B2aJ1VwOgDzYYGXUFjNIh58k') != -1">
            <el-button class="black-font-btn" @click="openStartrRefundDialog(row)">发起退款</el-button>
          </template>
        </template>

        <!-- 确认退款 -->
        <template #sureRefund="{ row }">
          <template v-if="row.is_confirm_refund == 1 && authData.indexOf('h_ETrMwWza4qPH3fEihBsTOHe0FhSX') != -1">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)">确认退款</el-button>
          </template>
        </template>

        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 && authData.indexOf('h_62aLxCxovfel0OuSQACgaGvDqN9J') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>

        <!-- 打印 -->
        <template #printTicket="{ row }">
          <template v-if="row.or_type == 2">
            <el-button class="theme-font-btn" @click="printTicket(row)">打印</el-button>
          </template>
        </template>

        <!-- 团体检票 -->
        <template #groupTicket="{ row }">
          <template
            v-if="authData.indexOf('h_uQYjgL3juQF3wsmA7a0Nroz3XZyo') != -1 && row.is_group_check_order == 1">
            <el-button @click="onGroupTicket(row)">团体检票</el-button>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 门票订单详情 -->
    <AssocOrderTicketsDetail ref="ticketsDetailRef" @submit="refreshTable"></AssocOrderTicketsDetail>

    <!-- 发起退款 -->
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>

    <!-- 确认退款 -->
    <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>

    <!-- 团体检票 -->
    <GroupTicketInspec ref="groupTicket" @submit="onConfirmVerification"></GroupTicketInspec>

    <!-- 打印 -->
    <PrintOrder ref="printOrderRef"></PrintOrder>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { BasicApi, HotelApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import RefundDialog from "../../order/components/RefundDialog.vue";
  import AssocOrderTicketsDetail from "../components/AssocOrderTicketsDetail.vue";
  import GroupTicketInspec from "../components/GroupTicketInspec.vue";
  import PrintOrder from "../components/PrintOrder.vue";
  import { useStore } from "vuex";
  import {
    exportExcel,
    print,
    getPayStatusOptions,
    getAssocWorkStatusOptions,
    orderStatusColors,
  } from "@/utils/common.js";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  function refreshTable() {
    tableRef.value.tableLoad();
  }
  const payStatusOptions = ref(getPayStatusOptions());
  const workStatusOptions = ref(getAssocWorkStatusOptions());
  const payStatusLabelArr = ref([]);
  function getPayStatusLabelArr() {
    for (var key in payStatusOptions.value) {
      payStatusOptions.value[key] &&
        payStatusLabelArr.value.push({
          label: payStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[1].options = payStatusLabelArr.value;
  }
  const workStatusLabelArr = ref([]);
  function getWorkStatusLabelArr() {
    for (var key in workStatusOptions.value) {
      workStatusOptions.value[key] &&
        workStatusLabelArr.value.push({
          label: workStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[2].options = workStatusLabelArr.value;
  }

  onMounted(() => {
    getHotelData();
    hotelChange({ value: '' });
    getPayStatusLabelArr();
    getWorkStatusLabelArr();
    getSourceOptions();
    tableRef.value.tableLoad();
  });

  const filters = ref([
    {
      filterType: "search",
      name: "keyword",
      value: "",
      placeholder: "搜索订单号、套票名称、游客姓名、手机号",
    },
    {
      filterType: "select",
      name: "h_id",
      value: "",
      placeholder: "请选择酒店",
      options: [],
      lab: "h_name",
      val: "h_id",
      action: "hotelChange",
    },
    {
      filterType: "select",
      name: "sp_id",
      value: "",
      placeholder: "请选择景点",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "scenicSpotChange",
    },
    {
      filterType: "select",
      name: "dr_id",
      value: "",
      placeholder: "请选择餐厅",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "dinningChange",
    },
    {
      filterType: "select",
      name: "pom_id",
      value: "",
      placeholder: "请选择门店",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "storeChange",
    },
  ]);
  const groupFilters = ref([
    {
      filterType: "select",
      name: "source",
      value: "",
      label: "购买渠道",
      options: [],
      val: "id",
      lab: "name",
    },
    {
      filterType: "select",
      name: "pay_status",
      value: '2',
      label: "支付状态",
      options: [],
      val: "value",
      lab: "label",
      otherOptionsName: "work_status",
      showOtherOptionsValue: 2,
    },
    {
      filterType: "multipleSelect",
      name: "work_status",
      value: [],
      label: "业务状态",
      options: [],
      val: "value",
      lab: "label",
    },
    {
      filterType: "partDaterange",
      name1: "stime",
      value1: "",
      name2: "etime",
      value2: "",
      label: "支付时间",
    },
  ]);
  const tableRef = ref(null);  // 表格对象
  const tableColumns = ref([  // 表格配置
    {
      prop: "sn",
      label: "订单号",
      minWidth: 220,
    },
    {
      prop: "assoc_name",
      label: "套票名称",
      minWidth: 240,
    },
    {
      prop: "buy_num",
      label: "购买数量",
    },
    {
      prop: "refund_num",
      label: "退款数量",
    },
    {
      prop: "pay_money",
      label: "支付金额",
    },
    {
      prop: "refund_money",
      label: "退款金额",
    },
    {
      prop: "yh_money",
      label: "优惠金额",
    },
    {
      prop: "coupon_money",
      label: "代金券",
    },
    {
      prop: "agent_name",
      label: "分销商",
    },
    {
      prop: "source_text",
      label: "来源渠道",
    },
    {
      prop: "payway_name",
      label: "支付方式",
    },
    {
      prop: "pay_status_text",
      label: "支付状态",
      type: "customRender",
    },
    {
      prop: "work_status_text",
      label: "业务状态",
      type: "customRender",
    },
    {
      prop: "pay_time",
      label: "支付时间",
      minWidth: 170,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 400,
      bottons: [
        {
          name: "详情",
          action: "onDetailBtn",
          token: "h_dz3rIWTFMUE35Tk0iDXR1jnmdoVE",
        },
        {
          name: "打印",
          action: "printTicket",
          type: "customRender",
        },
        {
          name: "发起退款",
          action: "startrRefund",
          type: "customRender",
        },
        {
          name: "确认退款",
          action: "sureRefund",
          type: "customRender",
        },
        {
          name: "取消退款",
          action: "cancelRefund",
          type: "customRender",
        },
        {
          name: "团体检票",
          action: "groupTicket",
          type: "customRender",
        },
      ],
    },
  ]);
  const ticketsDetailRef = ref(null);  // 订单详情对象
  /**
   * 
   * 获取酒店选项
   * 
   * **/
  const getHotelData = () => {
    BasicApi.getHotelByProject({ is_verify: 1 }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 筛选条件-选择酒店
   * 
   * **/
  const hotelChange = (obj) => {
    filters.value[2].value = "";
    filters.value[3].value = "";
    filters.value[4].value = "";
    tableRef.value.tableLoad();
    HotelApi.getAssocListSelect({ h_id: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data.scenic_spot ? res.Data.scenic_spot : [];
        filters.value[3].options = res.Data.dinning_room ? res.Data.dinning_room : [];
        filters.value[4].options = res.Data.polymerization ? res.Data.polymerization : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 筛选条件-选择景点
   * 
   * **/
  const scenicSpotChange = () => {
    filters.value[3].value = "";
    filters.value[4].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-选择餐厅
   * 
   * **/
  const dinningChange = () => {
    filters.value[2].value = "";
    filters.value[4].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-选择门店
   * 
   * **/
  const storeChange = () => {
    filters.value[2].value = "";
    filters.value[3].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-获取购买渠道选项
   * 
   * **/
  const getSourceOptions = () => {
    BasicApi.orderSourceOptions().then((res) => {
      if (res.Code === 200) {
        groupFilters.value[0].options = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 详情按钮
   * 
   * **/
  const onDetailBtn = (row) => {
    if (row.or_type == 2) {
      // 门票
      ticketsDetailRef.value.openDialog(row);
    }
  }
  const initRefundRef = ref(null);  // 发起退款对象
  const currentRefundSn = ref("");  // 发起退款id
  /**
   * 
   * 退款按钮
   * 
   * **/
  const openStartrRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    initRefundRef.value.openDialog("发起退款", row.surplus_money);
  };
  /**
   * 
   * 退款确定按钮
   * 
   * **/
  const handleInitRefund = (data) => {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    HotelApi.initiateRefundOrder(parmas).then((res) => {
      initRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        initRefundRef.value.closeDialog();
        refreshTable();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const groupTicket = ref(null);  // // 团体检票对话框
  /**
    *
    * 团体检票按钮
    *
    **/
  const onGroupTicket = (row) => {
    groupTicket.value.openDialog(row.sn);
  }
  /**
    *
    * 团体检票确认核销按钮
    *
    **/
  const onConfirmVerification = (data) => {
    HotelApi.checkGroupTicket(data).then((res) => {
      groupTicket.value.closeDialogLoading();
      if (res.Code === 200) {
        groupTicket.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
    *
    * 确认退款按钮
    *
    **/
  const sureRefundRef = ref(null); // 退款对话框
  const openSureRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    sureRefundRef.value.openDialog(
      "确认退款",
      row.pay_money,
      row.confirm_refund_money
    );
  }
  /**
    *
    * 确认退款确定按钮
    *
    **/
  const handleSureRefund = (data) => {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    HotelApi.confirmRefundOrder(parmas).then((res) => {
      sureRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        sureRefundRef.value.closeDialog();
        refreshTable();
        ElMessage.success("操作成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 取消退款确定按钮
   * 
   * **/
  const onConfirmRefund = (row) => {
    HotelApi.cancelRefundOrder({ sn: row.sn }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("取消退款成功！");
        refreshTable();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  // 导出数据
  const propertiesConfig = ref([
    { field: "sn", displayName: "订单号" },
    { field: "assoc_name", displayName: "套票名称" },
    { field: "buy_num", displayName: "购买数量" },
    { field: "refund_num", displayName: "退款数量" },
    { field: "pay_money", displayName: "支付金额" },
    { field: "refund_money", displayName: "退款金额" },
    { field: "yh_money", displayName: "优惠金额" },
    { field: "coupon_money", displayName: "代金券" },
    { field: "agent_name", displayName: "分销商" },
    { field: "source_text", displayName: "来源渠道" },
    { field: "payway_name", displayName: "支付方式" },
    { field: "pay_status_text", displayName: "支付状态" },
    { field: "work_status_text", displayName: "业务状态" },
    { field: "pay_time", displayName: "支付时间" },
  ]);
  function processData(data) {
    data.length &&
      data.forEach((el) => {
        // el.checkinDate = el.sdate + "至" + el.edate;
        // el.packageName = el.hotel_package_type + "-" + el.hotel_package;
        // el.workStatusText = workStatusOptions.value[el.work_status];
      });
    return data;
  }
  /**
   * 
   * 导出按钮
   * 
   * **/
  function handleExport(data) {
    try {
      exportExcel("套票订单", processData(data), propertiesConfig.value);
      ElMessage.success({
        message: "导出成功！",
      });
    } catch (e) {
      ElMessage.error({
        message: "导出失败！",
      });
    }
  }
  /**
   * 
   * 订单打印按钮
   * 
   * **/
  function handlePrint(data) {
    print("套票订单", processData(data), propertiesConfig.value);
  }
  const printOrderRef = ref(null);
  /**
   * 
   * 订单列表打印按钮
   * 
   * **/
  const printTicket = (row) => {
    printOrderRef.value.openDialog(row.sn);
  }
</script>
<style lang="scss">
  .order-assoc {
    font-family: "Source Han Sans CN";

    .search-input {
      width: 310px !important;
    }

    .verification-dialog {
      .el-form-item {
        margin-bottom: 6px;
      }

      .el-form-item__error {
        display: none;
      }

      .tips {
        color: var(--theme-color);
        font-size: 12px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .el-input-group__append {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .content {
      padding: 15px 20px 20px;
    }

    .check-time {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    .font-gray {
      color: var(--text-gray-color);
    }

    .font-999 {
      color: var(--text-third-color);
    }

    .refund-money {
      width: 100%;
      padding: 20px 0;
      background-color: var(--theme-bg-color);
      text-align: center;
      border-radius: 10px;
      margin-bottom: 20px;
      color: var(--text-gray-color);

      >div {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
</style>